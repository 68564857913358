import React, { useMemo } from 'react'
import qs from 'querystringify'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import SearchForm from '../components/search/search-form'
import GCSESearch from '../components/search/gcse-search'

function Search({ location }) {
  const { search } = location

  const searchQuery = useMemo(() => {
    const { q: qsSearch } = qs.parse(search)
    return qsSearch || ''
  }, [search])

  const post = useMemo(() => {
    return {
      seo: {
        title: searchQuery ? `You searched for ${searchQuery}` : 'Search',
      },
    }
  }, [searchQuery])

  if (!searchQuery) {
    return (
      <Layout>
        <Seo post={post} />
        <StructuredData pageType="webPage" />
        <div className="container md:py-24 pt-8 mb-5 text-center">
          <div
            role="doc-subtitle"
            className="py-1 px-5 text-lg uppercase font-semibold"
          >
            WHAT CAN WE HELP YOU FIND?
          </div>
          <h1 className="mt-8 mb-16">{`Let's find it by searching below`}</h1>
          <SearchForm fullWidth initialValue={searchQuery} />
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="webPage" />
      <SearchForm initialValue={searchQuery} />
      <div className="py-10 lg:py-20 px-2 sm:px-4 md:px-4 lg:px-32 text-center">
        <p className="text-base text-gray-500 mb-3">SEARCH RESULTS FOR:</p>
        <div className="p-5">
          <h1 className="text-gray-700 mb-3">{searchQuery}</h1>
        </div>
      </div>
      <div className="px-2 sm:px-4 md:px-4 lg:px-32">
        <GCSESearch value={searchQuery} />
      </div>
    </Layout>
  )
}

export default Search

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { injectScript } from '../blocks/utils'

const SEARCH_ELEMENT_GNAME = 'lwSearchResultsOnly'

function GCSESearch({ value }) {
  const searchRef = useRef()
  const [isScriptReady, setIsScriptReady] = useState(false)

  useEffect(() => {
    if (window.google?.search) {
      setIsScriptReady(true)
      return
    }

    window.__gcse = window.__gcse || {}
    window.__gcse.initializationCallback = () => setIsScriptReady(true)

    injectScript('https://cse.google.com/cse.js?cx=24385e84a56681ceb')

    return () => (window.__gcse.initializationCallback = null)
  }, [])

  useEffect(() => {
    if (!isScriptReady) return
    if (!value) return

    const cseElementAPI = window.google.search.cse.element;

    if (!searchRef.current.querySelector('[id^="___gcse"]')) {
      cseElementAPI.render({
        div: searchRef.current,
        tag: 'searchresults-only',
        gname: SEARCH_ELEMENT_GNAME,
      })
    }

    const searchElement = cseElementAPI.getElement(SEARCH_ELEMENT_GNAME)

    searchElement.execute(value)
  }, [isScriptReady, value])

  return <div className="mb-8" ref={searchRef} />
}

GCSESearch.propTypes = {
  value: PropTypes.string,
}

export default GCSESearch
